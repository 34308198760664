<template>
  <router-view  @setIndex="getIndex"/>
</template>
<script>

export default{
  methods: {
    getIndex(index){
      this.$emit('setIndex', index);
    }
  }
}
</script>

